import React, { useState, useCallback } from 'react';
import { Switch } from '@headlessui/react';
import { Link } from 'react-router-dom';

const toggleDarkMode = () => {
  document.documentElement.classList.toggle('dark');
};

const Srinidhi: React.FC = () => {
  const [enabled, setEnabled] = useState(false);

  const handleToggleDarkMode = useCallback(() => {
    console.log('Toggle Dark Mode');
    toggleDarkMode();
    setEnabled(!enabled);
  }, [enabled]);

  return (
    <div className="relative min-h-screen bg-sky-50 dark:bg-gray-800">
      <div className="absolute top-4 right-4">
        <Switch
          checked={enabled}
          onChange={handleToggleDarkMode}
          className={`${enabled ? 'bg-blue-600 dark:bg-blue-300' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Toggle Dark Mode</span>
          <span
            className={`${enabled ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
      <div className="flex justify-center items-center min-h-screen text-center">
        <div>
          <h1 style={{ fontFamily: 'Benne, sans-serif' }} className="text-3xl font-bold text-blue-600 dark:text-blue-300">
            srinidhi!
          </h1>
          <div className="text-lg font-medium text-slate-800 dark:text-gray-200 mt-4">
            i currently live in california and work independently. i grew up in the dc metropolitan area [dmv] and previously studied at ut austin.
          </div>
          <div className="text-lg font-medium text-slate-800 dark:text-gray-200 mt-4">
          <a
                href="https://github.com/srikrishnamurthy"
                className="hover:text-blue-500 dark:hover:text-blue-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                github
          </a>,{' '}
          <a
                href="https://www.linkedin.com/in/sri-krish"
                className="hover:text-blue-500 dark:hover:text-blue-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                linkedin
          </a>,{' '}
          <a
                href="https://twitter.com/__srinidhi"
                className="hover:text-blue-500 dark:hover:text-blue-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                twitter
          </a>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Srinidhi;
