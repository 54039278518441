// // src/app.tsx
// import React from 'react';
// import ReactDOM from 'react-dom';
// import Home from './components/Home';

// const App: React.FC = () => {
//   return <Home />;
// };

// ReactDOM.render(<App />, document.getElementById('root'));

// src/App.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Srinidhi from './components/Srinidhi'; // Import the Srinidhi component

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/srinidhi" element={<Srinidhi />} />
      </Routes>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));